import AppNavigation from "./app-navigation/AppNavigation";


function App() {
  return (
   <AppNavigation />
  );
}

export default App;
